import React from 'react';

import type { TabsActivatorProps } from '@components/pages/max/TabsAccordion/TabActivator';
import DefaultTabActivator from '@components/pages/max/TabsAccordion/TabActivator';

import styles from './TabActivator.module.css';

export default function TabActivator(props: TabsActivatorProps) {
    return (
        <DefaultTabActivator
            {...props}
            themeStyles={styles}
        />
    );
}
