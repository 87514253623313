import { useCallback, useRef } from 'react';

import clsx from 'clsx';

import { TabProps } from '../Tab';
import { useElementFocus } from '../hooks/useElementFocus.hook';
import defaultStyles from './TabActivator.module.css';

export interface TabsActivatorProps extends Omit<TabProps, 'children' | 'active'> {
    isSelected: boolean;
    index: number;
    focusIfSelected: boolean;
    className?: string;
    onClick(index: number): void;
    themeStyles?: typeof defaultStyles;
    theme?: 'legacy' | 'default';
    tabDirection?: 'column' | 'row';
}

export default function TabActivator({ index, isSelected, focusIfSelected, className, icon, title, onClick, themeStyles, theme = 'legacy', tabDirection = 'row' }: TabsActivatorProps): React.ReactElement {
    const handleClick = useCallback(() => onClick(index), [onClick, index]);
    const customRef = useElementFocus(focusIfSelected && isSelected);

    let styles = {} as typeof defaultStyles;
    if (typeof themeStyles === 'object') {
        styles = Object.keys(defaultStyles).reduce((accumulator, className) => Object.assign({}, accumulator, { [className]: [defaultStyles[className], themeStyles[className]].filter(Boolean).join(' ') }), {});
    } else {
        styles = defaultStyles;
    }
    //const customRef = useRef<HTMLDivElement>(null);
    const iconElement = icon && <div>{icon}</div>;
    return (
        <div
            role="tab"
            //tabIndex={0}
            tabIndex={focusIfSelected && isSelected ? -1 : 0}
            className={clsx(styles.tab, isSelected && styles.active, className)}
            onClick={handleClick}
            ref={customRef}
        >
            {theme === 'legacy' && (
                <>
                    {iconElement}
                    <span>{title}</span>
                </>
            )}
            {theme === 'default' && tabDirection === 'column' && (
                <div
                    ref={customRef}
                    className={clsx(styles.column, isSelected && styles.active)}
                >
                    {iconElement}
                    <span>{title}</span>
                </div>
            )}
            {theme === 'default' && tabDirection === 'row' && (
                <div
                    ref={customRef}
                    className={clsx(styles.row, isSelected && styles.active)}
                >
                    {iconElement}
                    <span>{title}</span>
                </div>
            )}
        </div>
    );
}
